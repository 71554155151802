<template>
  <UPage :ui="{ center: { base: 'lg:col-span-5' }, right: 'lg:col-span-5' }" class="h-full">
    <UContainer
      class="flex h-screen flex-col items-center"
      :ui="{
        padding: 'py-10 px-4 sm:px-6 lg:pl-8 lg:pr-0',
      }"
    >
      <div class="flex w-full max-w-md grow flex-col justify-center space-y-6">
        <slot name="header" />
      </div>
      <slot />
    </UContainer>
    <template #right>
      <div class="u-bg-secondary relative hidden size-full bg-cover bg-center bg-no-repeat lg:block">
        <slot name="right" />
      </div>
    </template>
  </UPage>
</template>
